<template lang="pug">

div#main-background.px-24.px-24
  vs-card#controller-panel( vs-type='flex' vs-justify='flex-end' style="position: fixed; display:block; z-index: 9999 !important; top : 85%;width:64%;")
    div#general.pt-6( vs-type='flex' vs-justify='flex-end' )
      // Rental Price
      vs-row(vs-w='12')
        vs-col.px-2.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='6', vs-sm='6', vs-xs='0')
        vs-col.px-2.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='4')
          vs-button.w-full(color="success" type="filled" @click="saveChange()") บันทึก
        vs-col.px-2.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='8')
         vs-button.w-full(color="dark" type="filled"  @click="$router.push('/backoffice/stock/')") กลับหน้าสต็อค

  vs-card.px-12.pt-6.pb-6( vs-justify='center' )
    div#general.px-16.pt-6
        h2 ข้อมูลทั่วไป
    div#general.px-24
      // General product group
      vs-row.pb-6(vs-w='12')
        template(v-if="productGroup.mainImage")
          .img-container.w-64.mx-auto.flex.items-center.justify-center.pt-12(vs-type='flex', vs-justify='flex-start', vs-align="flex-start")
            .grid-layout-container.alignment-block
              vs-row.py-2
                vs-col( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12',  style="font-size: 1rem; color: #0679ff; text-align:center;width:100%;") รูปภาพสำหรับเว็บไซต์
              vs-row
                vs-col( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  img.pb-6.responsive(:src="getImagepath(productGroup.mainImage)" alt="img" style="width: 200px")
                  i.material-icons.px-1(style=" cursor:pointer; font-size:18px; margin-right:5px;vertical-align:middle;"  @click="removeItemImage()" ) cancel
        template(v-else)
          template(v-if="dataImg")
            // Image Container
            .img-container.w-64.mx-auto.flex.items-center.justify-center.pt-12(vs-type='flex', vs-justify='flex-start', vs-align="flex-start")
              .grid-layout-container.alignment-block
                vs-row.py-2
                  vs-col( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12',  style="font-size: 1rem; color: #0679ff; text-align:center;width:100%;") รูปภาพสำหรับเว็บไซต์
                vs-row
                  vs-col( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                    img.responsive(:src="dataImg" alt="img" style="width: 200px")
                    i.material-icons.px-1(style=" cursor:pointer; font-size:18px; margin-right:5px;vertical-align:middle;"  @click="removeItemImage()" ) cancel

          // Image upload Buttons
          vs-col.px-6.pb-6.pt-6( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            .upload-img.mt-5(v-if="!dataImg")
              div.py-4(vs-type='flex', vs-justify='center', style="font-size: 1rem; color: #0679ff; text-align:center;") รูปภาพสำหรับเว็บไซต์
              input.hidden(type="file" ref="mainUploadImgInput" @change="updateCurrImg" accept="image/*")
              .upload_image(@click="$refs.mainUploadImgInput.click()" vs-type='flex', vs-justify='center', vs-align='flex-start' )
              //- div รูปภาพขนาดไม่เกิน 1 MB
              //- div สกุลภาพ .png หรือ .jpg

            // Image upload Buttons เส้นประ
            .modify-img.flex.justify-between.mt-3.default-bg
              input.hidden(type="file" ref="updateImgInput" @change="updateCurrImg" accept="image/*")


        // Product Name
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ชื่อสินค้า :

          vs-col.px-3(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            vs-input.w-full( v-model="productGroup.name" )

        // Code
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') รหัสสินค้า :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7') {{productGroup.code}}

        // Category
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ประเภท :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            multiselect(

                v-model="categorySelected",
                :options="categories",
                label="description"
                track-by="code",
              )
              span(slot="noResult").
                ไม่พบข้อมูล

        // Texture
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ชนิด :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            multiselect(
              v-model="textureSelected",
              :options="textures",
              label="description"
              track-by="code",
            )
            span(slot="noResult").
              ไม่พบข้อมูล

        // Rental Price
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ราคาเช่า :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            CurrencyValue.w-full( v-model="productGroup.rentalPrice")
          vs-col(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='2')
            .w-full.sm-btn-green( type="filled" @click="applyAllRentalPrice(productGroup.rentalPrice)") ใช้ทั้งหมด
        // Bail
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ค่าประกัน :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            CurrencyValue.w-full( v-model="productGroup.bail")
          vs-col(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='2')
            .w-full.sm-btn-green( type="filled" @click="applyAllBail(productGroup.bail)") ใช้ทั้งหมด



        // Sale Price
        //- vs-row.pb-6(vs-w='12')
        //-   vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ราคาขาย :
        //-   vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
        //-     CurrencyValue.w-full( v-model="salePrice")




        // เลือกสี และขนาด
        vs-row(vs-w='12')
          vs-col.px-6(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='6', vs-sm='6', vs-xs='12')
            label.typo__label(style="width:100%;") สี
              multiselect(
                v-model="color_value",
                :options="colors",
                :multiple="true",
                :group-select="true",
                label="description"
                track-by="code",
                placeholder="พิมพ์เพื่อค้นหา",

              )
                span(slot="noResult").
                  ไม่พบข้อมูล

          vs-col.px-6(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='6', vs-sm='6', vs-xs='12')
            label.typo__label(style="width:100%;") ขนาด
              multiselect(
                v-model="size_value",
                :options="sizes",
                :multiple="true",
                :group-select="true",
                label="description"
                track-by="code",
                placeholder="พิมพ์เพื่อค้นหา",

              )
                span(slot="noResult").
                  ไม่พบข้อมูล



  //- สี PANEL
  vs-card.px-3.pt-12.pb-12( vs-justify='center' v-if="color_value.length > 0 && size_value.length > 0" v-for="(color_i,colorInd) in color_value " :key="colorInd")
    div
      vs-row(vs-w='12')
        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3')
          .grid-layout-container.alignment-block(style="width:100%;")
            vs-row(vs-w='12')
              vs-col.px-6.typo__label(vs-type='flex', vs-justify='center', vs-align='center',vs-w='12' ) {{color_i.description}}
            vs-row(vs-w='12')
              vs-col.px-6.typo__label(vs-type='flex', vs-justify='center', vs-align='center',vs-w='12' )
                AddImageComponent(:key="color_i.code" :productGroupId="productgroupid" :color="color_i.code" productItem="")
                //- div รูปภาพขนาดไม่เกิน 1 MB
                //- div สกุลภาพ .png หรือ .jpg
                //- .upload-img(v-if="!prdImage[0]" )
                //-   input.hidden(type="file" ref="uploadImgInput" @change="updateCurrImg" accept="image/*")
                //- .upload_image(@click="$refs.uploadImgInput.click()" vs-type='flex', vs-justify='flex-start', vs-align='flex-start' style="margin: 0 auto;" )





        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='1', vs-sm='1', vs-xs='1')
        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='8', vs-sm='8', vs-xs='8')
          //- ขนาด SIZE
          .grid-layout-container.alignment-block(style="width:100%;")
              vs-row.pb-3(vs-w='12' v-for="(size_i,sizeInd) in size_value " :key="sizeInd")
                vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='2'  )
                  .grid-layout-container.alignment-block(style="width:100%;")
                    vs-row(vs-w='12')
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        p(style="font-size:1.3em; font-weight:bold !important;") ขนาด {{size_i.description}}

                vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='2'  )
                  .grid-layout-container.alignment-block(style="width:100%;")
                    vs-row(vs-w='12')
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        p(style="font-size:1.3em; ") จำนวน
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        CurrencyValue.w-full( v-model="productList[(colorInd * size_value.length)  + sizeInd]['inStock']")
                vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3')
                  .grid-layout-container.alignment-block(style="width:100%;")
                    vs-row(vs-w='12')
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        p(style="font-size:1.3em;") ราคาเช่า
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        CurrencyValue.w-full( v-model="productList[(colorInd * size_value.length)  + sizeInd]['rentalPrice']") )
                vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3')
                  .grid-layout-container.alignment-block(style="width:100%;")
                    vs-row(vs-w='12')
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        p(style="font-size:1.3em;") ค่าประกัน
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        CurrencyValue.w-full( v-model="productList[(colorInd * size_value.length)  + sizeInd]['bail']") )



</template>
<script>
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
import CurrencyValue from "./Components/CurrencyValue";
// import moduleProductGroup from "@/store/product-group/moduleProductGroup.js";
// import moduleProductList from "@/store/product-list/moduleProductList.js";
import shapeFormat from "@/mixins/shapeFormat.js";
import AddImageComponent from "./AddImageComponent.vue";

export default {
  mixins: [shapeFormat],
  props: ["msg"],
  data() {
    return {
      c10: ["px-10", "pb-10"],
      aa: ["ssdf", "2", "3", "4", "5"],
      rentalPrice: 0,
      bail: 0,
      salePrice: 0,
      amount: 0,
      categorySelected: {},
      categories: [
        { code: "c", description: "เสื้อโค้ท" },
        { code: "a", description: "เสื้อแบบ A" },
        { code: "b", description: "เสื้อแบบ B" }
      ],
      cates: [],
      textureSelected: {},
      textures: [
        { code: "W", description: "ผ้าวูล" },
        { code: "L", description: "ผ้าลินิน" },
        { code: "C", description: "ผ้าฝ้าย" }
      ],

      dataImg: "",
      productName: "",
      category: "",
      texture: "",
      code: "ไม่มี",
      colors: [
        { code: "R", description: "แดง" },
        { code: "B", description: "ดำ" },
        { code: "W", description: "ขาว" },
        { code: "Y", description: "เหลือง" }
      ],

      sizes: [
        { code: "S", description: "S" },
        { code: "M", description: "M" },
        { code: "L", description: "L" },
        { code: "XL", description: "XL" },
        { code: "3L", description: "3L" }
      ],
      productgroupid: "",
      color_value: [],
      size_value: [],
      selected: ["foo", "bar"],
      options: ["foo", "bar", "baz"],
      productGroup: {
        // name: "",
        // code: "",
        // mainImage: "",
        // category: "",
        // texture: "",
        // rentalPrice: 0,
        // bail: 0,
        // colors: [],
        // sizes: [],
        // qty: 0
      },
      prdImage: [],
      productList: []
    };
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },

  async created() {
    // INIT productGroup
    // INIT productGroup

    // if (this.productGroup.newRecord === false) {
    //   this.$router.push(
    //     "/backoffice/updateItem/" + this.productGroup.productGroupId
    //   );
    // }
    window.addEventListener("beforeunload", this.beforeWindowUnload);

    this.productgroupid = this.$route.params.productgroupid;
    this.productGroup = await this.$store.dispatch(
      "productGroup/set",
      this.productgroupid
    );

    await this.$store.dispatch(
      "productList/fetchColorList",
      this.productgroupid
    );

    await this.$store.dispatch(
      "productGroup/getAllData",
      this.productGroup.shopInfoId
    );

    this.categories = this.$store.getters["productGroup/categories"];
    this.textures = this.$store.getters["productGroup/textures"];
    this.colors = this.$store.getters["productGroup/colors"];
    this.sizes = this.$store.getters["productGroup/sizes"];

    this.categorySelected = await this.$store.dispatch(
      "productGroup/getCategoryObject"
    );

    this.textureSelected = await this.$store.dispatch(
      "productGroup/getTextureObject"
    );

    this.color_value = await this.$store.dispatch("productGroup/getColors");

    this.size_value = await this.$store.dispatch("productGroup/getSizes");

    setTimeout(async () => {
      await this.$store.dispatch(
        "productList/listInGroup",
        this.productgroupid
      );

      this.productList = await this.$store.getters["productList/products"];
      this.changeProductList();
    }, 500);
  },
  watch: {
    categorySelected: function(cate) {
      this.productGroup.category = cate.code;
    },
    textureSelected: function(value) {
      this.productGroup.texture = value.code;
    },
    color_value: {
      handler(value) {
        this.productGroup.colors = value;
        this.changeProductList();
      },
      deep: true
    },
    size_value: {
      handler(value) {
        this.productGroup.sizes = value;
        this.changeProductList();
      },
      deep: true
    },
    productList: {
      handler() {
        // this.$store.dispatch("productList/removeAll");
        // for (let i = 0; i < this.productList.length; i++) {
        //   var p = {};
        //   Object.assign(p, this.productList[i]);
        //   this.$store.dispatch("productList/add", p);
        // }
      },
      deep: true
    }
  },
  computed: {
     //  *** BEGIN : 2020-11-28 : Search By branches
     getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
  },
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  methods: {
    //*** CONFIRM LEAVE */

    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
    },

    confirmStayInDirtyForm() {
      return this.form_dirty && !this.confirmLeave();
    },

    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },

    //*** CONFIRM LEAVE */
    applyAllRentalPrice(value) {
      this.productList.forEach((item, ind) => {
        item.rentalPrice = value;
        this.$set(this.productList, ind, item);
      });
    },
    applyAllBail(value) {
      this.productList.forEach((item, ind) => {
        item.bail = value;
        this.$set(this.productList, ind, item);
      });
    },
    changeProductList() {
      // ลบที่เคยมีออกก่อน
      // this.$store.dispatch("productList/removeAll");
      // var prior = this.productList.length;
      // console.log(
      //   "REMOVE ALL :::: >>>> ",
      //   this.$store.state.productList.products
      // );

      // moduleProductList.actions.removeAll();
      // สร้าง Product Item ทีละตัว
      var list = [];
      Object.assign(list, this.productList);

      this.productList = [];

      for (let i = 0; i < this.color_value.length; i++) {
        for (let j = 0; j < this.size_value.length; j++) {
          var res = list.filter(
            c =>
              this.color_value[i].code === c.color &&
              this.size_value[j].code === c.size
          );

          // ถ้าเคยมีอยู่ใน list
          if (res.length >= 1) {
            this.$set(this.productList, i * this.size_value.length + j, res[0]); //(colorInd * size_value.length)  + sizeInd
          } else {
            var pl = {};
            Object.assign(pl, this.productGroup);
            delete pl.colors;
            delete pl.sizes;
            pl.color = this.color_value[i].code;
            pl.size = this.size_value[j].code;
            // ถ้าครั้งแรก ใส่เป็น standard price ไปก่อน
            pl["rentalPrice"] = 0;
            pl["bail"] = 0;
            // this.productList.unshift(pl);
            this.$set(this.productList, i * this.size_value.length + j, pl);
          }
        }
      }

      // this.$store.dispatch("productList/add", pl);
    },
    changeToName() {},
    async saveChange() {
      // update ที่ store update productGroup
      // ค่อยๆ add product list ทีละตัว โดยต้องเอาข้อมูล บางอย่างของ product group update ใหม่
      // เช่น productGroup.name  / productGroup.code / productGroup.category /productGroup.texture
      // ต้องใส่ main image ของแต่ละสี

      // console.log("this.categorySelected >>>> ", this.categorySelected);

      if (
        this.categorySelected === null ||
        this.categorySelected === undefined
      ) {
        this.$swal({
          icon: "error",
          title: "ยังไม่ได้เลือกข้อมูลประเภทของสินค้า"
        });
        return;
      }

      if (this.textureSelected === null || this.textureSelected === undefined) {
        this.$swal({
          icon: "error",
          title: "ยังไม่ได้เลือกข้อมูลชนิด"
        });
        return;
      }

      // 2020-10-03 เพิ่มต้องเลือกสีและ size ก่อน

      if (
        this.numberFormat(this.size_value.length) *
          this.numberFormat(this.color_value.length) <=
        0
      ) {
        this.$swal({
          icon: "error",
          title: "โปรดเลือกอย่างน้อย 1 สีและ 1 ไซส์"
        });
        return;
      }

      this.$vs.loading({ type: "radius" });

      var all = {};
      all.productGroup = this.productGroup;
      all.productList = this.productList;


      console.log("this.productGroup >>> ",this.productGroup);
      console.log("this.getCurrentBranch.branchId >>> ",this.getCurrentBranch.branchId);


      //*** ตอนนี้ใช้ field ProductGroup.group ในการ ใส่ สาขาปัจจุบันของ product

      all.productGroup.group = this.getCurrentBranch.branchId;

      var resp = null;
      try {
        resp = await this.$store.dispatch("productGroup/saveChange", all);

        if (resp.status === 200) {

          this.$vs.loading.close();

          this.$router.push(
            "/backoffice/updateItem/" + this.productGroup.productGroupId
          );
        }
      } catch (err) {
        resp = err.response;
        this.$vs.loading.close();
        return;
      }

    },
    removeItemImage() {
      this.$swal({
        title: "ต้องการลบรูปใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรูป"
      }).then(async result => {
        if (result.value) {
          this.dataImg = null;
          this.productGroup.mainImage = null;
          await this.$http.post(
            this.$store.state.apiURL +
              "/api/productgroup/remove-main-image/" +
              this.productGroup.productGroupId,
            {},
            this.$store.state.auth.apiHeader
          );
        }
      });
    },
    async updateCurrImg(input) {
      // this.$vs.loading({ type: "radius" });
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = async e => {
          this.dataImg = e.target.result;
          await this.$http.post(
            this.$store.state.apiURL +
              "/api/productgroup/save-main-image/" +
              this.productGroup.productGroupId,
            { base64Format: this.dataImg },
            this.$store.state.auth.apiHeader
          );
          // this.productGroup.mainImage = this.productgroupid;
          // console.log("i this.dataImg >>> ", this.dataImg);
        };
        reader.readAsDataURL(input.target.files[0]);

        this.$vs.loading.close();
      }
    }

    // buttonText: {
    //   type: String,
    //   default: "Click"
    // }
  },
  components: {
    Multiselect,
    "v-select": vSelect,
    CurrencyValue,
    AddImageComponent
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
// .upload_image{
//   background-color: green;
// }
@media only screen and (max-width: 578px) {
  #main-background {
    width: 100% !important;
    padding: 0 !important;
  }
  #controller-panel {
    width: 91% !important;
  }
}
</style>
